import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import ReactDOM from 'react-dom';
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import { Modal } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSearch,
    faSpinner, faHistory
} from '@fortawesome/free-solid-svg-icons';

const $ = require('jquery');
$.Datatable = require('datatables.net-dt');

const fieldMap = {
  Nombre_del_PPI: "Nombre del PPI",
  Monto: "Monto",
  Municipio: "Municipio",
  Dependencia_soli: "Dependencia Solicitante",
  Dependencia_Res: "Dependencia Responsable",
  Vida_util: "Vida Útil",
  Observaciones: "Observaciones",
  Alineacion_Plan: "Plan o programa",
  Alineacion_Eje: "Eje",
  Alineacion_Tema: "Tema",
  Alineacion_Objetivo: "Objetivo",
  Alineacion_Estrategia: "Estrategia",
  Alineacion_Linea: "Línea de acción ",
  Nombre_Origen_Financiamiento: "Origen",
  porcentaje: "Porcentaje",
  monto: "Monto",
  subsource: "Fondo",

  //Seccion Descripción general
  Descripcion_General: "Descripción general del PPI",
  Objetivo: "Objetivo",
  Beneficiarios:"Número de beneficiarios directos del PPI", 
  Resultados_ejecucion : "Resultados de la ejecución y beneficios económicos y/o sociales",
  
  //Sección analisis de la situación actual
  Justificación_PPI: "Justificación del PPI (problemática, necesidad u oportunidad)",
  Oferta_actual: "Oferta actual",
  Demanda_actual: "Demanda actual", 
  Interacción_actual: "Interacción actual",

  //Análisis de la situación optimizada
  Descripcion_optimizacion: "Descripción de la posible medida de optimización",
  Oferta_optimizada: "Oferta optimizada",
  Demanda_optimizada: "Demanda optimizada",
  Interaccion_optimizada: "Interacción optimizada",

  //Análisis de la situación con PPI
  Oferta_ppi: "Oferta con PPI",
  Demanda_ppi: "Demanada con PPI",
  Interaccion_ppi: "Interacción con PPI",

  //Alternativas de solución
  Nombre_alt: "Nombre de la alternativa",
  Descripcion: "Descripción",
  Total_costo: "Costo total (incluye IVA $)",
  Nombre_alternativa: "Nombre de la alternativa",
  Ventajas: "Ventajas del PPI",
  Desventajas: "Desventajas del PPI",

  //Consideraciones finales
  Nombre_ejecutor: "Nombre del ejecutor",
  Cargo_ejecutor: "Cargo",
  Comentarios: "Comentarios finales"

  // Agrega más campos según sea necesario
};


class Kardex extends Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.processNewData = this.processNewData.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.data = {};
        this.state = {
            showModal: false,
            currentChanges: null, // Para almacenar los datos de los cambios actuales
            user: '',
            avatar: '',
            ppi: '',
            color_aleatorio: '',
            data: false,
            url: '',
            userRole: 0,
            redirect: false,
            status: null,
            datatable: [],
            loading: false,
            status_list: {
                '1':'Validando',
                '2':'Devuelto Con Observaciones',
                '3':'Validando Con Correciones',
                '4':'Aprobado para llenado',
                // '5':'En Proceso',
                '6':'En revisión',
                '7':'Eliminado',
                '8':'Aprobado',
                '9':'No aprobado',
                '10':'Cancelado',
                '11':'Validando Reactivado',
            },
            table_list: {
                'Información_General': 'Información General',
                'Descripcion_PPI': 'Descripción del PPI',
                'Factibilidades' : 'Descripción del PPI',
                'Analisis_actual': 'Análisis de la situación actual',
                'Analisis_Optimizada': 'Análisis de la situación optimizada',
                'Analisis_ppi': 'Análisis de la situación con PPI',
                'SeccionAlternativa': 'Descripción de las ventajas y desventajas del PPI frente a las alternativas de solución desechadas',
                'Consideraciones_finales': 'Consideraciones finales',
                // 'approved': 'Aprobado',
                'deleted': 'Borrado',
                'sent': 'Enviado a revisión',
                // 'saved': 'Información Guardada'
                
            },
            actions_list: {
                'insert': 'Creado',
                'update': 'Actualizado',
                'updated': 'Actualizado',
                'deleted': 'Borrado',
                'sent': 'Enviado a revisión',
                'approved': 'Aprobado',
            }
        };
    }

    componentDidMount(){
        $('div.dataTables_filter input').addClass('mySeachClass');
        // this.processNewData();
        this.createTable();
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    handleShowChanges = (rowData) => {
        this.setState({
            showModal: true,
            currentChanges: rowData, // Guardar la información de los cambios
        });
    }

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            currentChanges: null,
            
        });
    }

    handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            this.handleCloseModal();
        }
    };


    onChangePPIHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value.toUpperCase();
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    searchPPI = () => {
        let search = this.state.ppi;
        
        this.setState({
            loading: true
        });


        
        axios.get(`${this.api}/kardex/${search}`)
        .then((response) => {
            this.setState({
                datatable: response.data
            });
            

            this.createTable();
            
            this.setState({
                loading: false
            });
        })
        .catch((error) => {
            console.log(error)
            
            this.setState({
                loading: false
            });
        });

        
    }

    processNewData = (newData) => {
        let dataText = '';
        try {
            let newDataJson = JSON.parse(newData);

            if (typeof newDataJson === 'string' && newDataJson.startsWith('http')) {
                dataText = `<a href="${newDataJson}" target="_blank">Descargar PPI</a>`;
            } else if (Array.isArray(newDataJson)) {
                newDataJson.forEach(dato => {
                    let readableKey = fieldMap[dato.Nombre] || dato.Nombre;
                    dataText += `<strong>${readableKey}</strong> <br />`;
                    dataText += `<em><strong>Valor anterior:</strong></em> ${dato.Anterior || 'N/A'} <br />`;
                    dataText += `<em><strong>Valor nuevo:</strong></em> ${dato.Nuevo || 'N/A'} <br /><br />`;
                });
            } else {
                dataText = 'El formato de los datos no es válido.';
            }
        } catch (error) {
            console.error('Error al parsear el JSON de new_data:', error);
            dataText = 'Error al procesar los datos';
        }
        return dataText;
    };

    createTable(){

        const datatale = this.state.datatable.map(kardex => {
            let fecha = kardex.created_at.split('T')[0].split('-').reverse().join('/');
            let dataText = this.processNewData(kardex.new_data); // Genera el contenido HTML a partir de `new_data`

            return [
                kardex.revision_count,
                fecha,
                kardex.user,
                // kardex.step,
                kardex.movement_type,
                kardex.table,
                dataText // Contenido procesado para la columna "Acciones"
            ];
        });
 
   
    
      

        const table = $(this.el).DataTable(
            {
                data: datatale,
                destroy: true,
                order: [0, 'asc'],
                columns: [
                     { title: '#'},
                    { title: 'Fecha'},
                    { title: 'Usuario'},
                    // { title: 'Perfil'},
                    { title: 'Tipo de movimiento'},
                    { title: 'Sección afectada'},
                    { title: 'Acciones'},
                ],
                language: {
                    "lengthMenu": "Mostrando _MENU_ registros por página",
                    "info": "Mostrando página _PAGE_ de _PAGES_",
                    "infoEmpty": "Sin registros encontrados",
                    "infoFiltered": "(filtrados de _MAX_ registros totales)",
                    "search": "Filtrar:",
                    "zeroRecords": "No se encontraron registros",
                    "paginate": {
                        "first":      "Primero",
                        "last":       "Último",
                        "next":       "Siguiente",
                        "previous":   "Anterior"
                    },
                },
                columnDefs: [
                    {
                        targets: [0],
                        createdCell: (td, cellData, rowData) =>{
                              // revison count
                            console.log('cellData:', cellData);
                            ReactDOM.render(
                                <div
                                id={rowData.folio}
                            > 
                                {cellData}
                            </div>, td
                        );

                        }
                       
                    },
                    {
                        targets: [1],
                        createdCell: (td, cellData, rowData) => {
                            // Para nombre user
                            console.log('cellData:', rowData);
                    
                            // Renderizar el componente con ReactDOM.render
                            ReactDOM.render(
                                <div id={rowData.folio}>
                                    {cellData}
                                </div>,
                                td
                            );
                        },
                    },
                    
                    {
                        targets: [2],
                        createdCell: (td, cellData, rowData) =>{
                              // Para nombre perfil
                            console.log('cellData:', cellData);

                            ReactDOM.render(
                            
                                <div
                                    id={rowData.folio}
                                > 
                                    {cellData}
                                </div>, td
                        );

                        },
                            
                       
                    },
                    // {
                    //     targets: [3],
                    //     createdCell: (td, cellData, rowData) =>
                    //     ReactDOM.render(
                    //             <div
                    //                 id={rowData.folio}
                    //                 // onClick={() => {
                    //                 // this.handleClickActivateOrNot(rowData);
                    //                 // }}
                    //             > 
                    //             {
                    //                 this.state.status_list[cellData]
                    //             } 
                    //             </div>, td
                    //     ),
                    // },
                    {
                        targets: [3],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                              // Tipo de movimiento
                                <div className={cellData}
                                    id={rowData.folio}
                                > 
                                    {
                                    this.state.actions_list[cellData]
                                    }
                                </div>, td
                        ),
                    },
                   
                    {
                        // secccion afectada
                      targets: [4],
                      createdCell: (td, cellData, rowData) =>{
                          console.log('cellData:', cellData);
                          ReactDOM.render(
                              <div
                                  id={rowData.folio}
                                  // onClick={() => {
                                  // this.handleClickActivateOrNot(rowData);
                                  // }}
                              > 
                              {
                                  this.state.table_list[cellData]
                              } 
                              </div>, td
                      );

                      }
                     
                  },
                    {
                        targets: [5], // Cambia este número por la columna donde quieres el botón
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div>
                               <button className="btn btn-info" style={{'margin-left':'15px'}} onClick={() => this.handleShowChanges(cellData)}>
                                    {
            
                                        <FontAwesomeIcon style={{'color':'#FFFFFF'}} icon={faHistory} size="1x" />                                        
                                    } Ver historial
                                </button>
                            </div>, 
                            td
                        ),
                    },
                    
                ]
            }
        );

        table.on( 'search.dt', function () {
            localStorage.setItem( 'SeachFilter', table.search() );
        } );
        if(localStorage.getItem('SeachFilter')!=null && localStorage.getItem('SeachFilter')!="")
                table.search( localStorage.getItem('SeachFilter') ).draw();
    }

    handleClick = (element, status) => {
        let id = element[0];
        let user = JSON.parse(localStorage.getItem('user'));
        let path;
        path = `/security/user/${id}`;
        
        this.setState({
            url: path,
            redirect: true,
            userRole: user.fk_role
        });
    }
    handleClickActivateOrNot = (element) => {
        let id = element[0];
        
        axios.delete(`${this.api}/users/${id}`)
        .then((response) => {
            window.location.reload();
        })
        .catch((error) => {
            console.log(error)
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Historial de PPI'} subtitle={'Historial de cambios por PPI'} catalog={'on'} path={'users'} security={true} back={'/projects'}/>                    
                        <div className="col-10 offset-1 list">
                            <div className="col-12 d-inline-flex p-2" style={{'margin-bottom':'10px'}}>
                                <label style={{'margin-top':'5px'}}>Folio: </label>
                                <input 
                                    type="text" 
                                    className="form-control col-2" 
                                    name='ppi' 
                                    onChange={this.onChangePPIHandler} 
                                    style={{'margin-left':'15px'}} 
                                    value={this.state.ppi}
                                    onSubmit={this.searchPPI}
                                    />
                                <button className="btn btn-primary" style={{'margin-left':'15px'}} onClick={this.searchPPI}>
                                    {
                                        this.state.loading ? 
                                        <FontAwesomeIcon style={{'color':'#FFFFFF'}} icon={faSpinner} spin size="1x" />
                                        :
                                        <FontAwesomeIcon style={{'color':'#FFFFFF'}} icon={faSearch} size="1x" />                                        
                                    } Buscar
                                </button>
                            </div>
                            <table className="display col-12" width="100%" ref={el => this.el = el}></table>

                            
                            {this.state.showModal && (
                  <div className="modal-overlay">
                    <div className={`modal-content ${this.state.currentChanges.includes("Descargar PPI") ? 'small-modal' : ''}`}>
  <h3>{this.state.currentChanges.includes("Descargar PPI") ? "PPI aprobado" : "Modificación realizada"}</h3>
  <div dangerouslySetInnerHTML={{ __html: this.state.currentChanges }} />
  <div className="button-container">
    <div className="cancel-button" onClick={this.handleCloseModal}>
      Cancelar
    </div>
  </div>
</div>

                  {/* <div className="modal-content">
                      <h3>Modificación realizada</h3>
                    
                      <div dangerouslySetInnerHTML={{ __html: this.state.currentChanges }} />
                      <div className="button-container">
                          <div className="cancel-button" onClick={this.handleCloseModal}>
                              Cancelar
                          </div>
                      </div>
                  </div> */}
                {/* <div className={`modal-content ${this.state.currentChanges.includes("Descargar PPI") ? 'small-modal' : ''}`}>
  <h3>Modificación realizada</h3>
  <div dangerouslySetInnerHTML={{ __html: this.state.currentChanges }} />
  <div className="button-container">
    <div className="cancel-button" onClick={this.handleCloseModal}>
      Cancelar
    </div>
  </div>
</div> */}
              </div>
              
                    
                       )}
                   
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Kardex;
